import React from 'react';
import ArticlePage from '../components/ArticlePage';
import {RawPageProps} from '../interfaces';
import {useStaticQuery, graphql} from 'gatsby';
import CtaCarousel from '../components/CtaCarousel';

const ADVOCACY_ARTICLE_CONST = {
  CTA: {
    title: 'Speaking up for change',
    subtitle:
      '<p>Read more about how we’re promoting a circular economy for plastics.</p>',
    buttonLink: {
      buttonText: 'See All Articles',
      href: '/advocacy/',
      color: 'yellow',
      textColor: 'black',
      openInNewWindow: false,
    },
  },
};

const advocacyArticlePagesQuery = graphql`
  query {
    allSanityAdvocacyArticlePage {
      nodes {
        id
        fullUrl
        title
        metaDescription
        publishedDate(formatString: "MMM D, YYYY")
        sortDate: publishedDate(formatString: "X")
        slug {
          _key
          current
        }
        previewImage {
          src {
            asset {
              url
              fluid(maxWidth: 350) {
                __typename
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          title
        }
      }
    }
  }
`;

export default (props: RawPageProps) => {
  const advocacyArticlePages = useStaticQuery(advocacyArticlePagesQuery);
  let cards = [];
  advocacyArticlePages.allSanityAdvocacyArticlePage.nodes.map(
    (article, index) => {
      const card = {
        title: article.title,
        fullUrl: article.fullUrl,
        image: article.previewImage,
        description: article.metaDescription,
        accent: {
          secondary: {
            accent: article.publishedDate,
          },
        },
        parentPath: 'advocacy',
      };
      cards.push(card);
    },
  );

  return (
    <ArticlePage
      {...props.pageContext}
      parentPath="advocacy"
      endSocialIndex={0}
    >
      <CtaCarousel
        title={ADVOCACY_ARTICLE_CONST.CTA.title}
        subtitle={ADVOCACY_ARTICLE_CONST.CTA.subtitle}
        ctaCards={cards}
        buttonLink={ADVOCACY_ARTICLE_CONST.CTA.buttonLink}
      />
    </ArticlePage>
  );
};
